<template>
  <div>
    <a-card title="日常收支">
      <a-row :gutter="16">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="名称, 备注" allowClear @search="search" />
        </a-col>

        <a-col :span="11">
          <a-month-picker style="width: 100px;margin-right: 12px" valueFormat="YYYY-MM" :format="'YYYY-MM'"  @change="onChangePicker" placeholder="选择月份" />
          <a-button type="primary" @click="onExport">导出</a-button>

        </a-col>

        <div style="margin-bottom: 12px; float: right;">
          <a-button type="primary" icon="plus" style="margin: 0 8px;" @click="openFormModal(form)">新增日常收支</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{value ? '激活' : '冻结'}}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger" size="small" :disabled="item.is_void">{{ item.is_void ? '已作废' : '作废'}}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal v-model="visible" :form="targetItem" :clientsItems="clientsItems" :chargeItems="chargeItems" :suppliersItems="suppliersItems" :handlerItems="handlerItems" :accountsItems="accountsItems" @create="create" @update="update" />
  </div>
</template>

<script>
  import { clientsOption, suppliersOption, chargeItemsOption, userOption, accountsOption } from '@/api/option'
  import { getChargeOrderNumber } from '@/api/data'
  import { chargeOrdersList, chargeOrdersVoid } from '@/api/finance'

  export default {
    name: 'Warehouse',
    components: {
      FormModal: () => import('./FormModal.vue'),
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
          },
          {
            title: '编号',
            dataIndex: 'number',
          },
          {
            title: '收支类型',
            dataIndex: 'type_display'
          },
          {
            title: '客户',
            dataIndex: 'client_name'
          },
          {
            title: '供应商',
            dataIndex: 'supplier_name'
          },
          {
            title: '收支项目',
            dataIndex: 'charge_item_name'
          },
          {
            title: '结算账户',
            dataIndex: 'account_name'
          },
          {
            title: '应收/付金额',
            dataIndex: 'total_amount'
          },
          {
            title: '实收/付金额',
            dataIndex: 'charge_amount'
          },
          {
            title: '经手人',
            dataIndex: 'handler_name'
          },
          {
            title: '发生日期',
            dataIndex: 'handle_time'
          },
          {
            title: '备注',
            dataIndex: 'remark'
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: '156px'
          },
        ],
        searchForm: { search: '', page: 1, page_size: 16 },
        pagination: { current: 1, total: 0, pageSize: 50 },
        loading: false,
        items: [],
        clientsItems: [],
        suppliersItems: [],
        chargeItems: [],
        handlerItems: [],
        accountsItems: [],
        visible: false,
        targetItem: {},
        form: {},
        importLoading: false,
      };
    },
    computed: {
    },
    methods: {

      onExport() {
        import("../../../utils/xlsx.js").then((excel) => {
          //tHeader 为导出后的表头
          let tHeader = [], filterVal = [], list = []
          tHeader = [ "单号", "供应商", "经手人", "录入时间","发生日期","付款银行","优惠金额", "合计金额",  "备注", ];
          filterVal =[ "number", "supplier_name", "handler_name", "create_time","handle_time", "PayBank","discount_amount", "allAmount", "remark", ];
          list = this.exportItems
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename:`日常收支`+ this.getCurrentDate(),
            autoWidth: true,
            bookType: "xlsx",
          });
        });

      },

      getCurrentDate() {
        let today = new Date();
        let year = today.getFullYear();
        let month = (today.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要+1，并且补0到两位数
        let day = today.getDate().toString().padStart(2, '0'); // 补0到两位数
        let formattedDate = `${year}${month}${day}`; // 拼接年月日
        return formattedDate;
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
            filterVal.map((j) => {
              if (j === "timestamp") {
                return parseTime(v[j]);
              } else {
                return v[j];
              }
            })
        );
      },


      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        chargeOrdersList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
          this.exportItems=[]
          this.items.forEach(item=>{
            const obj=item
            // obj.discount_amount=parseFloat(item.discount_amount)
            this.exportItems.push(obj)
          })

        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      create(item) {
        // this.items.splice(0, 0, item);
        this.list();
      },
      update(item) {
        this.items.splice(this.items.findIndex(i => i.id == item.id), 1, item);
      },

      onChangePicker(date, dateString) {
        // // 会传过来2个参数，都是'2024-09'这样的时间
        // console.log(54,date)
        // console.log(541,dateString)

        // let date1 = new Date('2024-09-01T00:00:00Z'); // UTC 时间

        let formatter = new Intl.DateTimeFormat('zh-CN', {
          timeZone: 'Asia/Shanghai',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          // hour: '2-digit',
          // minute: '2-digit',
          // second: '2-digit'
        });

        //
        let year = date.toString().split("-")[0]
        let month = date.toString().split("-")[1]
        let firstDay = new Date(parseInt(year), parseInt(month)-1, 1); // 注意：月份是从0开始计数的，所以9月是8
        let lastDay = new Date(parseInt(year), parseInt(month), 1); // 月份为9表示10月，但设置为0则回退到上个月的最后一日

        let first_day1 =  formatter.format(firstDay).replace("/","-").replace("/","-") ;
        let last_day1 =  formatter.format(lastDay).replace("/","-").replace("/","-") ;

        this.searchForm.start_date =  first_day1;
        this.searchForm.end_date = last_day1;
        this.search();
      },

      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      openFormModal(item) {
        clientsOption({ page_size: 999999, is_active: true }).then(data => {
          this.clientsItems = data.results;
        });
        suppliersOption({ page_size: 999999, is_active: true }).then(data => {
          this.suppliersItems = data.results;
        });
        chargeItemsOption({ page_size: 999999 }).then(data => {
          this.chargeItems = data.results;
        });
        userOption({ page_size: 999999, is_active: true }).then(data => {
          this.handlerItems = data.results;
        });
        accountsOption({ page_size: 999999, is_active: true }).then(data => {
          this.accountsItems = data.results;
        });
        getChargeOrderNumber().then(data => {
          this.targetItem = { ...item, ...{ number: data.number } };
        });
        this.visible = true;
      },
      voidItem(item) {
        chargeOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>
